import React from 'react'
import { graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Flex, Box, Heading, SimpleGrid, Text } from '@chakra-ui/react'

import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import Section from '../components/layout/Section'
import SEO from '../components/elements/SEO'
import Hero from '../components/elements/Hero'

const InsightsPage = ({ data }) => {
  const page = data.prismicInsightspage

  return (
    <Layout>
      <SEO
        meta_title={page.data.meta_title}
        meta_description={page.data.meta_description}
      />

      <Hero>
        <BackgroundImage
          Tag="div"
          fluid={page.data.hero_image.fluid}
          css={`
            padding-top: 180px;
          `}
        >
          <Container py={['12em', '12em', '16em']} />
        </BackgroundImage>
      </Hero>

      <Section id="insights" bg="gray.50">
        <Container>
          <Flex flexWrap="wrap" mx={-4}>
            <Box width={['full']} mb={12} px={4}>
              <Heading as="h1" mb={12}>
                {page.data.title.text}
              </Heading>
              <Text
                dangerouslySetInnerHTML={{
                  __html: page.data.content.html,
                }}
                width={['full', 'full', 3 / 4]}
              />
            </Box>

            <SimpleGrid columns={[1, 1, 2, 3]} spacing={9} px={4}>
              {data.allPrismicPost.edges.map(({ node: post }) => (
                <Box
                  key={post.id}
                  as="article"
                  bg="white"
                  p={6}
                  borderColor="gray.100"
                  borderWidth={1}
                >
                  <p
                    css={`
                      margin-bottom: 0;
                    `}
                  >
                    {post.data.date}
                  </p>
                  <Box as="h3">{post.data.title.text}</Box>
                  <Link to={`/insights/${post.uid}`}>Read More →</Link>
                </Box>
              ))}
            </SimpleGrid>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default InsightsPage

export const query = graphql`
  query InsightsPage {
    prismicInsightspage {
      data {
        meta_description
        meta_title
        content {
          html
        }
        hero_image {
          url
          fluid(maxWidth: 1500) {
            ...GatsbyPrismicImageFluid
          }
        }
        title {
          text
        }
      }
    }
    allPrismicPost(sort: { fields: data___date, order: DESC }, limit: 1000) {
      edges {
        node {
          data {
            date(formatString: "MMM Do, YYYY")
            content {
              html
            }
            title {
              text
            }
          }
          id
          uid
        }
      }
    }
  }
`
